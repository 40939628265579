import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
// import Img from 'gatsby-image'
import SEO from "./../../components/seo"

// s4n
import FragmentPageDetails from '../../model/fragments/nodePage/page-details'
import { useIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
// import ProductRelated from "./../../components/s4n/Shop/Product/related"
// import Subpages from "./../../components/s4n/Menu/subpages"
// import DrupalModuleParagraphsContainer from '../../components/drupal/module/paragraphs/container'
import NotFoundPage from './../../pages/404'
import useReplacementNode from './../../hooks/drupal/parseContentProcessor/useReplacement/Node'

const parse = require('html-react-parser');



const ComponentStateless = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const hasH1TagInBodyField = (node) => {
    const html = node.body ? node.body.value : ``;
    let hasH1TagInBodyField = false;
    const htmlReactParserOptions = {
        replace: (htmlNode, index) => {
            if ('tag' === htmlNode.type && 'h1' === htmlNode.name
            ) {                               
                if (!hasH1TagInBodyField) {
                  hasH1TagInBodyField = true;
                }

                // If the function does not return anything, or returns undefined, then the default behaviour will occur and the parser will continue was usual.
                // return undefined;
            }
        }
    }
    parse(html, htmlReactParserOptions);
    return hasH1TagInBodyField;
  }
  
  const oNodeDefaultDataInCaseNodeIsMissing = {
    field_metatag: null,
    body: null,
    relationships: null,
  }

  const { data } = props;
  const node = data.nodePage || oNodeDefaultDataInCaseNodeIsMissing;
  
  // prepare/extract data
  const oReplacement = useReplacementNode(node);
  //let nodeBodyField = node.body ? node.body.value : null;
  const nodeBodyField = useParseInlineImages(node, true, oReplacement); // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
  
  const hasH1Tag = hasH1TagInBodyField(node);

  // let nodeParagraphField = node.relationships && node.relationships.field_paragraph
  // let nodeParagraphField = useParseBodyInlineImages(node.relationships.field_paragraph)

  if (null === node) {
    // return null;
    return <NotFoundPage />;
  }

  return (
    <Layout node={node}>
        <SEO nodeId={node.drupal_internal__nid} title={node.title} field_metatag={node.field_metatag} />

        {/* <div className="container">
          <h1>{ node.title }</h1>
          <small><em>{ node.created }</em></small>
        </div> */}

        {!hasH1Tag &&
          <section className="page-title-alt _bg-primary position-relative">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1 className="py-5">{node.title}</h1>
                </div>
              </div>
            </div>
          </section>
        }

        {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>
          <Img fluid={ node.relationships.field_image.localFile.childImageSharp.fluid } />
        </div> */}

        {nodeBodyField}

        {/* <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div> */}

        {/* <DrupalModuleParagraphsContainer>
          { nodeParagraphField }
        </DrupalModuleParagraphsContainer> */}

    </Layout>
  )
}

export default ComponentStateless

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
// @see: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-source-wordpress#query-with-acf-flexible-content
// @see: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-source-wordpress#query-with-acf-flexible-content
/*

pageContext:
    id: "56a4c09a-ab66-5cf9-9e48-42b7e8e37393"
    drupal_id: "abd2fd65-dc6d-484b-8cb6-a0c61e16227d"
    langcode: "pl"
    language: "en"
    intl:
        language: "en"
        languages: (2) ["pl", "en"]
        messages: {soft4net_all_rights_reserved: "All rights reserved. Copyright Soft4Net.", soft4net_project_realization: "Project and realization - Soft4Net", soft4net_more: "More", soft4net_home: "Home", soft4net_links: "Links", …}
        routed: true
        originalPath: "/portfolio"
        redirect: true
        defaultLanguage: "pl"

*/
export const query = graphql`
    query(
      $language: String! # this is set by gatsby-plugin-intl plugin, when it goes trough all default languages defined in plugin configuration in gatsby-config.js -> languages: ['pl', 'en']
      # $id: String!, # @see: explanation why we do not use $id is in gatsby-node.js!
      $drupal_id: String!,
      # $drupal_internal__nid: String!,
      # $langcode: String!
    ) {
    nodePage(
      # Internal GatsbyJS ID ---> THIS HAS WRONG VALUE FOR OTHER LANGUAGE, WHY???!!!
      # id: {
      #   eq: $id
      # },
      drupal_id: {
        eq: $drupal_id
      },
      # drupal_internal__nid: {
      #   eq: $drupal_internal__nid
      # },
      # @see: explanation why we use $language instead of $langcode is in gatsby-node.js!
      # langcode: {
      #   eq: $langcode
      # }
      langcode: {
        eq: $language
      }

    ) {
        ...FragmentPageDetails
    }
  }
`
