import { graphql } from "gatsby"
import FragmentProductRelated from '../commerceProductDefault/product-related'

export const query = graphql`
    fragment FragmentPageDetails on node__page {

        id
        drupal_id
        drupal_internal__nid
        internal {
            type
        }
        langcode
        link: path {
            url: alias
            langcode
        }
        title
        body {
            summary
            value
        }
        created
        field_summary
        field_metatag {
            # basic
                title
                description
                abstract
                keywords
            # advanced
                canonical_url
                cache_control
        }



        # field_fake
        related_collection_mediaImageNodes {
            field_media_image {
                alt
                title
                width
                height
            }
            relationships {
                field_media_image {
                    drupal_id
                }
            }
        }
        related_collection_fileFileNodes {
            # __typename
            drupal_id
            drupal_internal__fid
            langcode
            localFile {
                childImageSharp {
                    fluid(
                        # maxWidth: 1920,
                        # maxWidth: 340,
                        srcSetBreakpoints: [ 1200 ]
                    ) {
                        # ...GatsbyImageSharpFluid
                        # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                        # ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    # fluid(maxWidth: 100) {
                    #     ...GatsbyImageSharpFluid_withWebp
                    # }
                    # fixed(width: 400, height: 400) {
                    #     ...GatsbyImageSharpFixed
                    # }
                }
            }
        }



        relationships {
            __typename



            field_related_products {
                # ... on CommerceProduct {
                ... on commerce_product__default {
                    # ...FragmentProductRelated

                    title
                    path {
                        pid
                        alias
                        langcode
                    }
                    body {
                        value
                    }
                    created

                    relationships {
                        bundle: __typename

                        variations {
                            ... on commerce_product_variation__default {

                                id: drupal_id # JSON:API response id
                                type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                                variation_id: drupal_internal__variation_id
                                sku
                                title
                                price {
                                    number
                                    currency_code
                                    formatted
                                }
                                resolved_price {
                                    number
                                    currency_code
                                    formatted
                                }
                                calculated_price {
                                    number
                                    currency_code
                                    formatted
                                }
                                relationships {
                                    bundle: __typename

                                    field_images {
                                        localFile {
                                            childImageSharp {
                                                fluid(
                                                    # maxWidth: 340,
                                                    srcSetBreakpoints: [ 1200 ]
                                                ) {
                                                    # ...GatsbyImageSharpFluid_withWebp
                                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                                }
                                                # fluid(maxWidth: 100) {
                                                #     ...GatsbyImageSharpFluid_withWebp
                                                # }
                                                # fixed(width: 400, height: 400) {
                                                #     ...GatsbyImageSharpFixed
                                                # }
                                            }
                                        }
                                    }
                                }
                                commerce_stock_always_in_stock
                                field_stock_level {
                                    available_stock
                                }
                            }

                            # ... on commerce_product_variation__phone {

                            #     id: drupal_id # JSON:API response id
                            #     type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                            #     variation_id: drupal_internal__variation_id
                            #     sku
                            #     title
                            #     price {
                            #         number
                            #         currency_code
                            #         formatted
                            #     }
                            #     resolved_price {
                            #         number
                            #         currency_code
                            #         formatted
                            #     }
                            #     calculated_price {
                            #         number
                            #         currency_code
                            #         formatted
                            #     }
                            #     relationships {
                            #         bundle: __typename

                            #         field_images {
                            #             localFile {
                            #                 childImageSharp {
                            #                     fluid(maxWidth: 255) {
                            #                         ...GatsbyImageSharpFluid_withWebp
                            #                     }
                            #                 }
                            #             }
                            #         }
                            #     }
                            #     commerce_stock_always_in_stock
                            #     field_stock_level {
                            #         available_stock
                            #     }
                            # }

                        }

                    }
                }
            }



            # @todo: Extract as fragment
            # field_related_products {
            #     ... on CommerceProduct {
            #         ...FragmentProductRelated
            #     }
            # }

            # field_paragraph {
            #   ... on paragraph__section {
            #     field_section_title
            #     field_section_header
            #     field_section_content_short {
            #       value
            #     }
            #   }
            #   ... on paragraph__paragraph_container {
            #     relationships {
            #       field_paragraph {
            #         ... on paragraph__section {
            #           field_section_title
            #           field_section_header
            #           field_section_content_short {
            #             value
            #           }
            #         }
            #         ... on paragraph__carousel_item {
            #           field_carousel_item_content {
            #             value
            #           }
            #           field_carousel_item_image_print
            #           field_carousel_item_link {
            #             uri
            #           }
            #         }
            #       }
            #     }
            #   }
            # }

        }

    }
`